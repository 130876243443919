<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-equip
            label="설비"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 점검유형 -->
          <c-select
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="equipmentCheckKindCd"
            label="점검유형"
            v-model="searchParam.equipmentCheckKindCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="MIM_CHECK_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="checkStatusCd"
            label="진행상태"
            v-model="searchParam.checkStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 점검일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="점검예정기간"
            defaultStart="-1M"
            defaultEnd="1M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 점검일 -->
          <c-datepicker
            :range="true"
            name="period2"
            label="점검실제기간"
            defaultStart="-1M"
            defaultEnd="1M"
            v-model="period2"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        설비점검결과
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <div :class="['text-grid-etc', 'grid-status-div', getColorStatus(props.row.checkStatusCd)]">
                  {{ props.row.checkStatusName }}
                  <span>
                    <q-badge v-if="props.row.checkStatusCd==='MCSC000020'" color="negative" :label="$language('지연')" />
                  </span>
                </div>
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus2(props.row.checkResultCd)]">
                    {{ props.row.checkResultName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.equipmentCdName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ $language('예정') + ': ' + props.row.checkScheduleDate }} | {{ $language('실제') + ': ' + props.row.checkDate }}  | {{ props.row.checkUserName }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">{{$language('데이터가 존재하지 않습니다.')}}</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import CDatepicker from '../../../../components/CDatepicker.vue';
export default {
  components: { CDatepicker },
  name: "equipment-inspection",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          equipmentTypeCd: '',
          grade: '',
          inspectionCycleCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            style: 'width:200px',
            label: "설비명(코드)",
            align: "center",
            sortable: true,
          },
          {
            name: "checkScheduleDate",
            field: "checkScheduleDate",
            label: "점검예정일",
            style: 'width:100px',
            align: "center",
            type: "link",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        equipmentCheckKindCd: null,
        checkStatusCd: null,
        startSchYmd: '',
        endSchYmd: '',
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        equipmentTypeCd: '',
        inspectionCycleCd: '',
        grade: '',
        equipmentCd: '',
      },
      period: [],
      period2: [],
      editable: true,
      checkNoPlanItems: [
        { code: 'Y', codeName: '무계획' },
        { code: 'N', codeName: '계획' }
      ], // 점검진행상태
      colorItems: [
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.min.equipment.plan.list.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.result.delete.url;
      // list setting
      if (this.popupParam.equipmentTypeCd) {
        this.searchParam.equipmentTypeCd = this.popupParam.equipmentTypeCd;
        this.searchParam.inspectionCycleCd = this.popupParam.inspectionCycleCd;
        this.searchParam.grade = this.popupParam.grade;
      }
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startSchYmd = this.period[0];
        this.searchParam.endSchYmd = this.period[1];
      } else {
        this.searchParam.startSchYmd = '';
        this.searchParam.endSchYmd = '';
      }
      if (this.period2) {
        this.searchParam.startYmd = this.period2[0];
        this.searchParam.endYmd = this.period2[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(evt, row) {
      this.popupOptions.title = row.equipmentCdName
      this.popupOptions.param = {
        minEquipmentCheckId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
        title: row.noPlanResultEnrollFlag === 'N' ? '설비점검결과' : '무계획 설비점검결과',
        selectData: [],
        noPlan: 'N',
      };
      this.popupOptions.target = () =>
        import(`${"./equipmentResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    /* eslint-disable no-unused-vars */ 
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'MCSC000005': // 계획수립
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'MCSC000015': // 점검완료
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'MCSC000010': // 점검중
          cls = 'txt-box-grid text-accent-box';
          break;
        case 'MCSC000020': // 지연
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
    getColorStatus2(cd) {
      var cls = '';
      switch(cd) {
        case 'MCRC000005': // 일반
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'MCRC000010': // 화기
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
  },
};
</script>
<style>
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>